@use "breakpoints";
@use "colors";
@use "layout";

// @use "stacking";
@use "typography";

.global-header {
  background-color: colors.$black;
  inline-size: 100%;
  inset-block-start: 0;
  position: fixed;
  text-decoration: none;
  user-select: none;
  z-index: 201;
}

.content {
  @include layout.module-content;

  align-items: center;
  block-size: var(--global-header-height);
  flex-direction: row;
  transition: padding 0.2s ease-in-out;
}

.equinox-tagline {
  background-color: colors.$white;
  block-size: 36px;
  display: block;
  inline-size: 200px;
  margin: 0;
  mask-image: url("https://assets.cdn-equinox.com/images/equinox-tagline.svg");
  mask-repeat: no-repeat;
  overflow: hidden;
  padding-block-start: 36px;
}

.equinox {
  @include layout.equinox-logo;

  margin-inline-end: auto;
}

.main-nav {
  color: colors.$white;
  font-weight: 600;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  transition: color 0.2s ease-in-out;
}

.main-nav-item[data-shape="cta"] {
  a {
    @include typography.cta;

    align-items: center;
    block-size: 36px;
    cursor: pointer;
    display: flex;
    inline-size: 100px;
    justify-content: center;
    padding: 0;
    transition-duration: 0.2s;
    transition-property: background-color, color, border-color;
    transition-timing-function: ease-in;

    &:hover {
      opacity: 1;
    }
  }

  &[data-theme="light"] a {
    background-color: colors.$white;
    border: 1px solid transparent;
    color: colors.$black;
  }

  &[data-theme="dark"] a {
    border: 1px solid rgba(colors.$white, 0.6);
  }

  &[data-theme="light"] a:hover {
    background-color: colors.$black;
    border-color: colors.$white;
    color: colors.$white;
  }

  &[data-theme="dark"] a:hover {
    border-color: colors.$white;
  }
}

.main-nav-link,
.sub-nav-title,
.sub-nav-link {
  &:hover {
    color: colors.$gray;
    cursor: pointer;
  }
}

.sub-nav {
  list-style: none;
  opacity: 0;
  padding: 0;
}

/**
 * mobile styles
 */
@media (width < breakpoints.$xl) {
  .global-header {
    &::after {
      background-color: rgb(0 0 0 / 80%);
      content: "";
      display: block;
      inset-block-start: var(--global-header-height);
      opacity: 0;
      position: absolute;
      transition: opacity 0.5s ease-in-out;
      z-index: 2;
    }

    &:has(.show-menu-button[aria-expanded="true"]) {
      &::after {
        block-size: 100vh;
        inline-size: 100vw;
        inset-inline-start: 0;
        opacity: 1;
      }
    }
  }

  .content {
    z-index: 3;
  }

  .visit-a-club-cta {
    @include typography.body-extra-small;

    align-items: center;
    background-color: colors.$white;
    border: 1px solid transparent;
    color: colors.$black;
    display: flex;
    justify-content: center;
    margin-inline-end: 16px;
    padding: 8px 12px;
  }

  .main-nav {
    background-color: colors.$black;
    block-size: calc(100vh - var(--global-header-height));
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    inline-size: 300px;
    inset: var(--global-header-height) -300px 0 auto;
    letter-spacing: 0.32px;
    line-height: 1.1;
    overflow: auto;
    padding: 0 40px 122px;
    position: absolute;
    transition: right 0.5s ease-in-out;
    z-index: 4;
  }

  .show-menu-button[aria-expanded="true"] ~ .main-nav {
    inset-inline-end: 0;
  }

  .main-nav-item,
  .sub-nav-item + .sub-nav-item {
    margin-block-start: 24px;
  }

  .main-nav-item {
    order: 4;

    &[data-shape="cta"] a {
      min-inline-size: 20rem;
    }

    &:nth-child(4) {
      border-block-end: 1px solid colors.$medium-gray;
      padding-block-end: 24px;
    }

    &:nth-child(8) {
      border-block-end: 1px solid colors.$medium-gray;
      order: 3;
      padding-block-end: 24px;
    }

    &:nth-child(9) {
      order: 2;
    }

    &:nth-child(10) {
      order: 1;
    }

    &:nth-child(11) {
      order: 1;
    }
  }

  .sub-nav {
    opacity: 1;
  }

  .sub-nav img,
  .sub-nav .right-arrow,
  .sub-nav-title {
    display: none;
  }
}

/**
 * desktop styles
 */
@media (width >= breakpoints.$xl) {
  .equinox {
    block-size: 46px;
    inline-size: 160px;
    padding-block-start: 46px;
  }

  .show-menu-button {
    display: none;
  }

  .visit-a-club-cta {
    display: none;
  }

  .main-nav {
    display: contents;
    font-size: 1.4rem;
    letter-spacing: normal;
    line-height: 1.3;
  }

  .main-nav-item,
  .sub-nav-item {
    transition: opacity 0.2s ease-in-out;

    .caret {
      display: inline-block;
      inset-block-start: -2px;
      margin-inline-start: 7px;
      position: relative;
    }
  }

  .main-nav-item {
    align-items: center;
    block-size: 24px;
    display: flex;

    &[data-shape="cta"] a {
      @include typography.cta-small;
    }

    &:nth-child(4),
    &:nth-child(7) {
      border-inline-end: 1px solid colors.$medium-gray;
      padding-inline-end: 24px;
    }

    & + .main-nav-item {
      margin-inline-start: 24px;
    }
  }

  .sub-nav {
    background-color: colors.$black;
    block-size: 208px;
    border-block-start: 1px solid rgb(255 255 255 / 7%);
    display: none;
    inline-size: 100%;
    inset: var(--global-header-height) 0 0;
    justify-content: center;
    pointer-events: none;
    position: fixed;
    transition: opacity 0.2s ease-in-out;
    z-index: 5000;
  }

  .sub-nav-item {
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    animation-name: fadein;
    display: inline-block;
    inline-size: 355px;
    opacity: 0;

    .sub-nav-image {
      block-size: 124px;
      inline-size: 355px;
      margin-block-end: 14px;
      transition: opacity 0.2s ease-in-out;
    }

    .sub-nav-link {
      align-items: center;
      display: flex;
      flex-flow: row wrap;

      &:hover {
        .sub-nav-image {
          opacity: 0.6;
        }
      }
    }

    & + .sub-nav-item {
      margin-inline-start: 22px;
    }
  }

  .sub-nav-title {
    background-color: transparent;
    border: none;
    font-weight: inherit;
    padding: 0;
  }

  .sub-nav-title[aria-expanded="true"] {
    color: colors.$gray;

    & + .sub-nav {
      display: flex;
      opacity: 1;
      pointer-events: initial;
    }
  }
}

@media (width >= breakpoints.$xl) {
  .main-nav-item {
    &:nth-child(4),
    &:nth-child(7) {
      padding-inline-end: 24px;
    }

    & + .main-nav-item {
      margin-inline-start: 24px;
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

body[data-vwo-web3650] {
  @media (width < breakpoints.$xl) {
    .main-nav-item {
      order: 5;

      &[title="Clubs"],
      &[title="Member Benefits"],
      &[title="Classes"],
      &[title="Training"] {
        border-block-end: none;
        order: 3;
        padding-block-end: 0;
      }

      &[title="Alumni"] {
        order: 2;
      }

      &[title="Sign in"] {
        order: 1;
      }

      &[title="Visit a Club"] {
        order: 4;
      }

      &[title="Join Online"] {
        border-block-end: 1px solid colors.$medium-gray;
        order: 4;
        padding-block-end: 24px;
      }
    }
  }
}
